import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import FormNotify from "../../components/FormNotify";
import {apiOrganisation} from "../../services/api";
import {AppContext} from "../../services/context";
import requestOrganisation from "../../services/requestOrganisation";

const FormOrganisation = () => {
  const [refresh, setRefresh] = useState(0);
  const authCtx = useContext(AppContext);
  const {user, onUserChange} = authCtx;
  const header = {
    headers: {Authorization: `Bearer ${user.token}`},
  };
  const [jsData, setJsData] = useState({
    registrationReference: "",
    organisationName: "",
    firstNameAdmin: "",
    lastNameAdmin: "",
    emailAdmin: "",
    cnib: "",
    city: "",
    phone: "",
    countryAdmin: "",
    countryOrganisation: "",
    cityOrganisation: "",
    sectorOrganisation: "",
    birhtDate: "",
    address: "",
    firstnameLeader: "",
    lastnameLeader: "",
    emailLeader: "",
    phoneLeader: "",
    nationalReference: "",
    countries: {},
    cities: {},
    departmentList: null,
    licenceTypes: {},
    licenceType: "",
    createdDateLicence: "",
    expireDateLicence: "",
    twilioServiceId: "",
  });
  const [organisationName, setOrganisationName] = useState("");
  const [firstNameAdmin, setFirstNameAdmin] = useState("");
  const [lastNameAdmin, setLastNameAdmin] = useState("");
  const [emailAdmin, setEmailAdmin] = useState("");
  const [cnib, setCnib] = useState("");
  const [cityAdmin, setCityAdmin] = useState("");
  const [phoneAdmin, setPhoneAdmin] = useState("");
  const [countryAdmin, setCountryAdmin] = useState("");
  const [countryOrganisation, setCountryOrganisation] = useState("");
  const [cityOrganisation, setCityOrganisation] = useState("");
  const [sectorOrganisation, setSectorOrganisation] = useState("");
  const [birhtDate, setBirhtDate] = useState("");
  const [fullNameLeader, setFullNameLeader] = useState("");
  const [emailLeader, setEmailLeader] = useState("");
  const [emailOrganisation, setEmailOrganisation] = useState("");
  const [phoneLeader, setPhoneLeader] = useState("");
  const [phoneOrganisation, setPhoneOrganisation] = useState("");
  const [nationalReference, setNationalReference] = useState("");
  const [department, setDepartment] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [registrationReference, setRegistrationReference] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [createdDateLicence, setCreatedDateLicence] = useState("");
  const [expireDateLicence, setExpireDateLicence] = useState("");
  const [twilioServiceId, setTwilioServiceId] = useState("");
  const [licenceType, setLicenceType] = useState("");
  const [userInfos, setUserInfos] = useState({
    lastName: "",
    firstName: "",
    cnib: "",
    department: "",
    fonction: "",
    birthDate: "",
  });
  const [notifyBg, setNotifyBg] = useState("");
  const [notifyTitle, setNotifyTitle] = useState("");
  const [notifyMessage, setNotifyMessage] = useState("");
  const [formValidate, setFormValidate] = useState("needs-validation");
  const [firstCall, setFirstCall] = useState(1)
  const [modalNotifyMsg, setModalNotifyMsg] = useState('')
  const notifyRef = useRef()
  let navigate = useNavigate();
  const {id} = useParams();
  useEffect(() => {
    const url =
      id !== undefined && id != null
        ? `${apiOrganisation.get}/${id}`
        : apiOrganisation.getJsData;
    if (firstCall === 1) {
      setFirstCall(2)
      requestOrganisation
        .get(url, header)
        .then((res) => {
          //setDatas(res.data.employeeResponseList);
          //setList(res.data.employeeResponseList);

          if (id !== undefined && id != null) {
            jsData.registrationReference = res.data.registrationReference;
            setOrganisationName(res.data.organisationName);
            setFirstNameAdmin(res.data.firstNameAdmin);
            setLastNameAdmin(res.data.lastNameAdmin);
            setEmailAdmin(res.data.emailAdmin);
            setEmailOrganisation(res.data.emailOrganisation);
            setCnib(res.data.cnib);
            setCityAdmin(res.data.cityAdminReference);
            res.data.cityAdmin = res.data.cityAdminReference;
            setPhoneAdmin(res.data.phoneAdmin);
            setPhoneOrganisation(res.data.phoneOrganisation);
            setCountryAdmin(res.data.countryAdminReference);
            res.data.countryAdmin = res.data.countryAdminReference;
            setCityOrganisation(res.data.cityOrganisationReference);
            res.data.cityOrganisation = res.data.cityOrganisationReference;
            setCountryOrganisation(res.data.countryOrganisationReference);
            res.data.countryOrganisation = res.data.countryOrganisationReference;
            setSectorOrganisation(res.data.sectorOrganisation);
            setBirhtDate(res.data.birhtDate);
            setFullNameLeader(res.data.fullNameLeader);
            setEmailLeader(res.data.emailLeader);
            setPhoneLeader(res.data.phoneLeader);
            setNationalReference(res.data.nationalReference);
            setCreatedDate(res.data.createdDate);
            setCreatedDateLicence(res.data.licence.createdDateLicence)
            setExpireDateLicence(res.data.licence.expireDateLicence)
            setTwilioServiceId(res.data.licence.twilioServiceId)
            setLicenceType(res.data.licence.licenceType)
          }
          console.log(res.data);
          setJsData(res.data);
        })
        .catch((error) => {
        });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    configNotify(
      "loading",
      "",
      "Ajout d’une nouvelle organisation en cours..."
    );
    const {countries, cities, ...data} = jsData
    console.log(data);
    requestOrganisation
      .post(apiOrganisation.post, data, header)
      .then((res) => {
        console.log("enregistrement ok");
        setRefresh(refresh + 1);
        configNotify(
          "success",
          "Ajout réussi",
          "Les informations ont bien été enrégistrées"
        );
        setModalNotifyMsg("Les informations ont bien été ajouter")
        notifyRef.current.click()
      })
      .catch((error) => {
        console.log(error);
        configNotify(
          "danger",
          "Ouppss!!",
          "Une erreur est survenue, veuillez reesayer plus tard..."
        );
      });
  };

  const handleSubmitEdite = (e) => {
    e.preventDefault();
    console.log(jsData)
    configNotify("loading", "", "Modification de l'organisation en cours...");
    requestOrganisation
      .put(apiOrganisation.put, jsData, header)
      .then((res) => {
        console.log("enregistrement ok");
        setRefresh(refresh + 1);
        configNotify(
          "success",
          "Modification réussi",
          "Les informations ont bien été enrégistrées"
        );
        setModalNotifyMsg("Les informations ont modifier")
        notifyRef.current.click()

      })
      .catch((error) => {
        console.log(error);
        configNotify(
          "danger",
          "Ouppss!!",
          "Une erreur est survenue, veuillez reesayer plus tard..."
        );
      });
  };

  const fValidate = (cl) => {
    setFormValidate(cl);
  };

  const configNotify = (bg, title, message) => {
    setNotifyBg(bg);
    setNotifyTitle(title);
    setNotifyMessage(message);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 className="h2">
          {
            (id !== undefined && id != null) ? "Modification des informations d’une organisation" : "Ajout d’une organisation"
          }
        </h1>
      </div>

      <div className="row my-4">
        {notifyBg !== "" ? (
          <FormNotify
            bg={notifyBg}
            title={notifyTitle}
            message={notifyMessage}
          />
        ) : null}
        <div className="col-12 mt-3">
          <form
            className={"row " + formValidate}
            onSubmit={
              id !== undefined && id != null ? handleSubmitEdite : handleSubmit
            }
            noValidate
          >
            <span className="text-bold">Informations sur l’organisation</span>
            <hr/>
            <div className="col-12 col-md-6">
              <div className="mb-3 mt-3">
                <label htmlFor="organisationName" className="form-label">
                  Nom de la structure
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="organisationName"
                  placeholder="Entrer le nom de la structure"
                  value={organisationName}
                  onChange={(e) => {
                    e.preventDefault();
                    setOrganisationName(e.target.value);
                    jsData.organisationName = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le nom de la structure
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="createdDate" className="form-label">
                  Date de création
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="createdDate"
                  placeholder="Entrer la date de création"
                  value={createdDate}
                  onChange={(e) => {
                    e.preventDefault();
                    setCreatedDate(e.target.value);
                    jsData.createdDate = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer la date de création
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="fullNameLeader" className="form-label">
                  Nom et prénom(s) du responsable
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullNameLeader"
                  placeholder="Entrer le nom et prénom(s) du responsable"
                  value={fullNameLeader}
                  onChange={(e) => {
                    e.preventDefault();
                    setFullNameLeader(e.target.value);
                    jsData.fullNameLeader = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le nom et prénom(s) du responsable
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phoneLeader" className="form-label">
                  Téléphone du responsable
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="phoneLeader"
                  placeholder="Entrer le numéro de téléphone du  responsable"
                  value={phoneLeader}
                  onChange={(e) => {
                    e.preventDefault();
                    setPhoneLeader(e.target.value);
                    jsData.phoneLeader = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le numéro de téléphone du responsable
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-3 mt-3">
                <label htmlFor="nationalReference" className="form-label">
                  Référence nationale (facultatif)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nationalReference"
                  placeholder="Entrer la référence nationale"
                  value={nationalReference}
                  onChange={(e) => {
                    e.preventDefault();
                    setNationalReference(e.target.value);
                    jsData.nationalReference = e.target.value;
                    setJsData(jsData);
                  }}

                />
                <div className="invalid-feedback">
                  Veuillez entrer la référence nationale
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="departmentList" className="form-label">
                  Départements (facultatif)
                </label>

                <div className="input-group border-3">
                  {
                    departmentList.map((data, idx) => {
                      return (
                        <span className="input-group-text" key={idx}>{data + " X"}</span>
                      )
                    })
                  }
                  <input
                    type="text"
                    className="form-control"
                    id="departmentList"
                    placeholder=""
                    value={department}
                    onChange={(e) => {
                      e.preventDefault();
                      setDepartment(e.target.value);
                      jsData.departmentList = null;
                      setJsData(jsData);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setDepartmentList([...departmentList, e.target.value])

                      }
                    }}

                  />
                </div>
                <div className="invalid-feedback">
                  Veuillez entrer le ou les département(s)
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="emailLeader" className="form-label">
                  Email du responsable
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailLeader"
                  placeholder="Entrer l’adresse mail du responsable"
                  value={emailLeader}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmailLeader(e.target.value);
                    jsData.emailLeader = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer l’adresse mail du responsable
                </div>
              </div>
            </div>
            <span className="text-bold">
              Adresse et contacts de l’organisation
            </span>
            <hr/>
            <div className="col-12 col-md-6">
              <div className="my-3">
                <label htmlFor="countryOrganisation" className="form-label">
                  Pays
                </label>
                <select
                  id="countryOrganisation"
                  className="form-select"
                  value={countryOrganisation}
                  onChange={(e) => {
                    e.preventDefault();
                    setCountryOrganisation(e.target.value);
                    jsData.countryOrganisation = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                >
                  <option value="">Choisir le pays</option>
                  {Object.keys(jsData.countries).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {jsData.countries[key]}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">Veuillez choisir un pays</div>
              </div>
              <div className="mb-3">
                <label htmlFor="sectorOrganisation" className="form-label">
                  Adresse (facultatif)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sectorOrganisation"
                  placeholder="Entrer l’adresse"
                  value={sectorOrganisation}
                  onChange={(e) => {
                    e.preventDefault();
                    setSectorOrganisation(e.target.value);
                    jsData.sectorOrganisation = e.target.value;
                    jsData.address = e.target.value;
                    setJsData(jsData);
                  }}

                />
                <div className="invalid-feedback">
                  Veuillez entrer l’adresse
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="emailOrganisation" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="emailOrganisation"
                  placeholder="Entrer l’adresse mail de l'organisation"
                  value={emailOrganisation}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmailOrganisation(e.target.value);
                    jsData.emailOrganisation = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer l’adresse mail de l'organisation
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="mb-3 mt-3">
                <label htmlFor="cityOrganisation" className="form-label">
                  Ville
                </label>
                <select
                  className="form-select"
                  id="cityOrganisation"
                  value={cityOrganisation}
                  onChange={(e) => {
                    e.preventDefault();
                    setCityOrganisation(e.target.value);
                    jsData.cityOrganisation = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                >
                  <option value="">Choisir la ville</option>
                  {Object.keys(jsData.cities).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {jsData.cities[key]}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">
                  Veuillez choisir une ville
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phoneOrganisation" className="form-label">
                  Téléphone
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="phoneOrganisation"
                  placeholder="Entrer le numéro de téléphone"
                  value={phoneOrganisation}
                  onChange={(e) => {
                    e.preventDefault();
                    setPhoneOrganisation(e.target.value);
                    jsData.phoneOrganisation = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le numero de téléphone
                </div>
              </div>
            </div>

            <span className="text-bold">Administration de l’organisation</span>
            <hr/>
            <div className="col-12 col-md-6">
              <div className="mb-3 mt-3">
                <label htmlFor="lastNameAdmin" className="form-label">
                  Nom de l’administrateur
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastNameAdmin"
                  placeholder="Entrer le nom de l’administrateur"
                  value={lastNameAdmin}
                  onChange={(e) => {
                    e.preventDefault();
                    setLastNameAdmin(e.target.value);
                    jsData.lastNameAdmin = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le nom de l’administrateur
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="cnib" className="form-label">
                  CNI
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cnib"
                  placeholder="Entrer le numéro de la carte d’identité nationale "
                  value={cnib}
                  onChange={(e) => {
                    e.preventDefault();
                    setCnib(e.target.value);
                    jsData.cnib = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le numéro de la carte d’identité nationale
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="emailAdmin" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailAdmin"
                  placeholder="Entrer l’adresse mail"
                  value={emailAdmin}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmailAdmin(e.target.value);
                    jsData.emailAdmin = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer l’adresse mail
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="countryAdmin" className="form-label">
                  Pays (facultatif)
                </label>
                <select
                  className="form-select"
                  id="countryAdmin"
                  value={countryAdmin}
                  onChange={(e) => {
                    e.preventDefault();
                    setCountryAdmin(e.target.value);
                    jsData.countryAdmin = e.target.value;
                    setJsData(jsData);
                  }}

                >
                  <option value="">Choisir un pays</option>
                  {Object.keys(jsData.countries).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {jsData.countries[key]}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">Veuillez choisir un pays</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-3 mt-3">
                <label htmlFor="firstNameAdmin" className="form-label">
                  Prénom(s) de l’administrateur
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstNameAdmin"
                  placeholder="Entrer le prénom(s) de l’administrateur"
                  value={firstNameAdmin}
                  onChange={(e) => {
                    e.preventDefault();
                    setFirstNameAdmin(e.target.value);
                    jsData.firstNameAdmin = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le prénom(s) de l’administrateur
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="birhtDate" className="form-label">
                  Date de naissance
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="birhtDate"
                  placeholder="Entrer l’adresse mail"
                  value={birhtDate}
                  onChange={(e) => {
                    e.preventDefault();
                    setBirhtDate(e.target.value);
                    jsData.birhtDate = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer la date de naissance
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phoneAdmin" className="form-label">
                  Téléphone
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="phoneAdmin"
                  placeholder="Entrer le numero de téléphone"
                  value={phoneAdmin}
                  onChange={(e) => {
                    e.preventDefault();
                    setPhoneAdmin(e.target.value);
                    jsData.phoneAdmin = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le numero de téléphone
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="cityAdmin" className="form-label">
                  Ville (facultatif)
                </label>
                <select
                  className="form-select"
                  id="cityAdmin"
                  placeholder="Entrer la boîte postale"
                  value={cityAdmin}
                  onChange={(e) => {
                    e.preventDefault();
                    setCityAdmin(e.target.value);
                    jsData.cityAdmin = e.target.value;
                    setJsData(jsData);
                  }}

                >
                  <option value="">Choisir une ville</option>
                  {Object.keys(jsData.cities).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {jsData.cities[key]}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">
                  Veuillez choisir une ville
                </div>
              </div>
            </div>
            <span className="text-bold">Licence de l’organisation</span>
            <hr/>
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="createdDate" className="form-label">
                  Date de création de la licence
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="createdDate"
                  placeholder="Entrer la date de création"
                  value={createdDateLicence}
                  onChange={(e) => {
                    e.preventDefault();
                    setCreatedDateLicence(e.target.value);
                    jsData.createdDateLicence = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer la date de création
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="cnib" className="form-label">
                  Twilio token for SMS
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cnib"
                  placeholder="Entrer le numéro de la carte d’identité nationale "
                  value={twilioServiceId}
                  onChange={(e) => {
                    e.preventDefault();
                    setTwilioServiceId(e.target.value);
                    jsData.twilioServiceId = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer le numéro de la carte d’identité nationale
                </div>
              </div>

            </div>
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="createdDate" className="form-label">
                  Date d'expiration de la licence
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="createdDate"
                  placeholder="Entrer la date de création"
                  value={expireDateLicence}
                  onChange={(e) => {
                    e.preventDefault();
                    setExpireDateLicence(e.target.value);
                    jsData.expireDateLicence = e.target.value;
                    setJsData(jsData);
                  }}
                  required
                />
                <div className="invalid-feedback">
                  Veuillez entrer la date de création
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="countryAdmin" className="form-label">
                  Type de Licence
                </label>
                <select
                  className="form-select"
                  id="countryAdmin"
                  value={licenceType}
                  onChange={(e) => {
                    e.preventDefault();
                    setLicenceType(e.target.value);
                    jsData.licenceType = e.target.value;
                    setJsData(jsData);
                  }}

                >
                  <option value="">Choisir une licence</option>
                  {Object.keys(jsData.licenceTypes).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {jsData.licenceTypes[key]}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">Veuillez choisir une licence</div>
              </div>

            </div>
            <div className="modal-footer d-flex justify-content-start border-0">
              <Link
                className="btn btn-secondary me-2"
                to="/dashboard/organisations"
              >
                Fermer
              </Link>
              <button
                type="submit"
                className="btn btn-primary me-2"
                data-bs-dismiss="modal1"
                onClick={() => fValidate("was-validated")}
              >
                {(id !== undefined && id != null) ? "Enrégistrer les modifications" : "Ajouter"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="modal fade" id="notifyRef">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">

              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">{modalNotifyMsg}</div>

            <div className="modal-footer border-0 d-flex justify-content-start">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault()
                  setModalNotifyMsg('')
                  navigate("/dashboard/organisations")

                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <input type="hidden" ref={notifyRef} data-bs-toggle="modal" data-bs-target="#notifyRef" onClick={(e) => {
        e.preventDefault()
        setNotifyBg("")
      }}/>
    </>
  );
};

export default FormOrganisation;
